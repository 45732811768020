<script lang="ts" setup>
import BetterDate from '~/src/Domain/Shared/BetterDate/BetterDate'

defineProps<{
  fluid?: boolean
}>()

const year = new BetterDate().getYear()
</script>

<template>
  <ui-container
    class="bg-white"
    :fluid="fluid"
  >
    <div class="row">
      <div
        class="col-12 ps-3 p-3 d-flex justify-content-center justify-content-lg-start align-items-center"
      >
        <ui-img
          src="/img/logo-inverse.svg"
          height="40"
        />
        <span class="ps-3">
          {{
            $t('components.layout.footer.copyright', {
              year,
            })
          }}
          <span class="d-none d-md-inline">
            {{ $t('components.layout.footer.company_name') }}
          </span>
        </span>
      </div>
    </div>
  </ui-container>
</template>
